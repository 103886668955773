import { ServicesComponent } from './services/services.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { OrdersComponent } from './orders/orders.component';
import { LocationComponent } from './location/location.component';
import { UnitmanagerComponent } from './unitmanager/unitmanager.component';
import { AuthRoleGuard } from '../core/guards/authRole.guard';

const routes: Routes = [
    // { path: '', component: DashboardComponent , canActivate: [AuthRoleGuard],data: {
    //     accessRole: ['admin' , 'manager']
    // }  },
    // { path: 'calendar', component: CalendarComponent },
    // { path: 'employees', component: EmployeesComponent },
    { path: 'services', component: ServicesComponent , canActivate: [AuthRoleGuard],data: {
        accessRole: ['admin' , 'manager']
    }  },
    { path: 'unitmanager', component: UnitmanagerComponent , canActivate: [AuthRoleGuard],data: {
        accessRole: ['admin']
    } },
    // { path: 'booking', component: BookingComponent },
    { path: '', component: OrdersComponent , canActivate: [AuthRoleGuard],data: {
        accessRole: ['admin' , 'manager']
    } },
    { path: 'location', component: LocationComponent, canActivate: [AuthRoleGuard],data: {
        accessRole: ['admin' , 'manager']
    }  },
    // { path: 'chat', component: ChatComponent },
    // { path: 'kanban-board', component: KanbanComponent },
    // { path: 'settings', component: SettingsComponent },
    // { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
    // { path: 'email', loadChildren: () => import('./email/email.module').then(m => m.EmailModule) },
    // { path: 'pages', loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule) },
    // { path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UIModule) },
    // { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
    // { path: 'charts', loadChildren: () => import('./chart/chart.module').then(m => m.ChartModule) },
    // { path: 'form', loadChildren: () => import('./form/form.module').then(m => m.FormModule) },
    // { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
    // { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
    // { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule) },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
