<!-- vertical layout -->
<app-vertical *ngIf="isVerticalLayoutRequested()"></app-vertical>

<!-- horizontal layout -->
<app-horizontal *ngIf="isHorizontalLayoutRequested()"></app-horizontal>


<div class="modal-backdrop show d-flex justify-content-center align-items-center" style="z-index: 9999" *ngIf="loading|async">
    <div class="text-center" >
        <div class="spinner-border text-white" style="width: 4rem; height: 4rem" role="status">
            <span class="sr-only">Đang tải...</span>
        </div>
        <button class="btn btn-primary mt-2 mx-2 d-block" (click)="offLoading()">Huỷ</button>
    </div>
</div>
