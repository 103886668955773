// import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';

import { PagesRoutingModule } from './pages-routing.module';

import { NgbNavModule, NgbDropdownModule, NgbTooltipModule, NgbPaginationModule, NgbDatepickerModule, NgbDateAdapter, NgbDateParserFormatter, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgApexchartsModule } from 'ng-apexcharts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FullCalendarModule } from '@fullcalendar/angular';
// import { DndModule } from 'ngx-drag-drop';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { DashboardComponent } from './dashboard/dashboard.component';
// import { EcommerceModule } from './ecommerce/ecommerce.module';
// import { EmailModule } from './email/email.module';
// import { UIModule } from './ui/ui.module';
// import { IconsModule } from './icons/icons.module';
// import { ChartModule } from './chart/chart.module';
// import { FormModule } from './form/form.module';
// import { TablesModule } from './tables/tables.module';
// import { MapsModule } from './maps/maps.module';
import { ServicesComponent } from './services/services.component';
import { CustomAdapter, CustomDateParserFormatter } from '../shared/services/common.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';
import { OrdersComponent } from './orders/orders.component';
import { LightboxModule } from 'ngx-lightbox';
import { TranslateModule } from '@ngx-translate/core';
import { LocationComponent } from './location/location.component';
import { GoogleMapsModule } from "@angular/google-maps";
import { UnitmanagerComponent } from './unitmanager/unitmanager.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};

@NgModule({
  declarations: [DashboardComponent, ServicesComponent,  OrdersComponent, LocationComponent, UnitmanagerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    // NgApexchartsModule,
    PerfectScrollbarModule,
    // DndModule,
    FullCalendarModule,
    // EcommerceModule,
    // EmailModule,
    // IconsModule,
    // ChartModule,
    // FormModule,
    // TablesModule,
    // MapsModule,
    // LeafletModule,
    WidgetModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    NgSelectModule,
    ChartsModule,
    LightboxModule,
    NgbCarouselModule,
    TranslateModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAmjEn7OoUoj3EPApR7SvqCWxamJJAb6ig'
    // }),
    GoogleMapsModule
    // Goog
    // AgmJsMarkerClusteredModule
    // AgmMarkerClustererModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class PagesModule { }
