<app-pagetitle title="{{'Báo cáo' | translate}}"
    [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<div class="card">
    <div class="card-body" style="min-height: 400px;">
        <div class="mb-0">
            <div class="mb-2">
                <h5>{{'Bộ lọc' | translate}}</h5>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label for="validationCustom01">{{'Thời gian' | translate}}</label>
                            <div>
                                <ng-template #t let-date let-focused="focused">
                                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                        [class.faded]="isHovered(date) || isInside(date)"
                                        (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                        {{ date.day }}
                                    </span>
                                </ng-template>
                                <div class="position-relative">
                                    <input type="text" class="form-control date" (click)="hidden=!hidden"
                                        name="daterange" autocomplete="off" [(ngModel)]="selected" readonly
                                        placeholder="{{'Khoảng thời gian' | translate}}" />
                                    <span class="clearDate" *ngIf="selected" (click)="clearDate()">x</span>
                                </div>

                                <ngb-datepicker #dp (select)="onDateSelection($event)" [displayMonths]="2"
                                    [dayTemplate]="t" outsideDays="hidden" [hidden]="hidden">
                                </ngb-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="flex-fill row">
                        <div class="col-4">
                            <div class="form-group">
                                <label for="validationCustom01">{{'Đơn vị' | translate}}</label>
                                <ng-select [(ngModel)]="filter.location_id" [clearable]="false" (ngModelChange)="getEmployees()">
                                    <ng-option [value]="''">{{'Tất cả' | translate}}</ng-option>
                                    <ng-option [value]="item.value" *ngFor="let item of dataOption">
                                        {{item.label}}</ng-option>
                                    <!-- <ng-option [value]="0"> {{'PAGE.REPORT.other' | translate}}</ng-option> -->
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label for="validationCustom01">{{'Nhân viên' | translate}}</label>
                                <ng-select [(ngModel)]="filter.user_id" [clearable]="false">
                                    <ng-option [value]="-1">{{'Tất cả' | translate}}</ng-option>
                                    <ng-option [value]="item.id" *ngFor="let item of listEmp">
                                        {{item.full_name}}</ng-option>
                                    <!-- <ng-option [value]="0"> {{'PAGE.REPORT.other' | translate}}</ng-option> -->
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label for="validationCustom01">{{'Đánh giá' | translate}}</label>
                                <ng-select [(ngModel)]="filter.level" [clearable]="false">
                                    <!-- <ng-option [value]="-1">{{'PAGE.REPORT.SelectIncidentType' | translate}}</ng-option>
                                    <ng-option [value]="item.id" *ngFor="let item of listType"> {{item.name}}</ng-option> -->
                                    <ng-option [value]="-1">{{'Tất cả' | translate}}</ng-option>
                                    <ng-option [value]="1">{{'Rất tốt' | translate}}</ng-option>
                                    <ng-option [value]="2">{{'Tốt' | translate}}</ng-option>
                                    <ng-option [value]="3">{{'Trung bình' | translate}}</ng-option>
                                    <ng-option [value]="4">{{'Không tốt' | translate}}</ng-option>
                                </ng-select>
                                <!-- <select class="form-control" [(ngModel)]="filter.status">
                                </select> -->
                            </div>
                        </div>
                   

                    </div>
                    <div style="min-width: 200px; padding-left: 15px">
                        <div class="form-group">
                            <label for="validationCustom01" class="d-block">&nbsp;</label>
                            <button class="btn btn-primary" (click)="submitFilter()">{{'Tìm kiếm' |
                                translate}}</button>
                            <button class="btn btn-dark ml-2" *ngIf="searching" (click)="clearFilter()">{{'Làm mới' |
                                translate}}</button>
                        </div>
                    </div>


                </div>

                <!-- End search -->
            </div>
            <div class="table-responsive">
                <table id="basic-datatable" class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
                    <thead>
                        <tr>
                            <!-- <th>STT</th> -->
                            <th>#</th>
                            <th>{{'Đánh giá' | translate}}</th>
                            <!-- <th>{{'PAGE.REPORT.trackCode' | translate}}</th>
                            <th>{{'PAGE.REPORT.Location' | translate}}</th> -->
                            <th>Nhân viên</th>
                            <th>Đánh giá chi tiết</th>
                            <!-- <th>{{'PAGE.REPORT.Status' | translate}}</th> -->
                            <!-- <th>{{'TABLE.CreatedDate' | translate}}</th> -->
                            <!-- <th>{{'TABLE.CreatedDate' | translate}}</th> -->
                            <th>Thời điểm</th>
                            <!-- <th>{{'TABLE.UpdatedDate' | translate}}</th> -->
                            <!-- <th>{{'TABLE.Actions' | translate}}</th> -->
                        </tr>
                    </thead>
                    <tbody *ngFor="let data of listData;let i=index">
                        <tr >
                            <td (click)="onEdit(data?.note, content)">{{i+(pageSize*pageIndex - pageSize + 1)}}</td>
                            <!-- <td style="text-decoration: underline; color: #204cd9 ;"
                                (click)="onDetailBooking(data, detailBooking)">#{{data?.id}}</td> -->
                            <td (click)="onEdit(data?.note, content)">
                                <span class="font-size-12 badge badge-danger" *ngIf="data.level == '4'">Không tốt</span>
                                <span class="font-size-12 badge badge-warning" *ngIf="data.level == '3'">Trung bình</span>
                                <span class="font-size-12 badge badge-primary" *ngIf="data.level == '2'">Tốt</span>
                                <span class="font-size-12 badge badge-success" *ngIf="data.level == '1'">Rất tốt</span>
                                <!-- {{mapRating[data.level]}} -->
                            </td>
                            <td (click)="onEdit(data?.note, content)">
                                {{getEmp(data?.full_name)}}
                            </td>
                            <td (click)="onEdit(data?.note, content)" class="d-flex">
                               <div *ngFor="let item of data?.note" >
                                    <span   class=" mr-1 p-1 rounded text-white {{ item === 'Tốt' || item === 'Sớm' || item === 'Hài lòng' || item === 'Không' 
                                    || item === 'Đầy đủ, kịp thời, chính xác bằng bản giấy và có hướng dẫn khi công khai bằng hình thức điện tử' ? 'bg-success' 
                                    : (item === 'Bình thường' || item === 'Đúng hạn'|| item === 'Đúng hạn' || item === 'Một lần' || item === 'Một cơ quan' || item === 'Đầy đủ, kịp thời, chính xác bằng bản giấy' ? 'bg-warning' : 'bg-danger') }}">
                                    {{item}}
                                    </span>
                                </div>
                            </td>
                            <!-- <td>
                                <ng-container *ngIf="data.location_id; else location_other">
                                    {{data?.location_detail?.name}}
                                </ng-container>
                                <ng-template #location_other>
                                    {{data?.location_other?.formatted_address}}
                                </ng-template>
                            </td> -->
                            <!-- <td>{{data?.details.}}</td> -->
                            <!-- <td>
                                <span class="badge badge-success" *ngIf="data.status == 'waiting'">Đơn hàng mới</span>
                                <span class="badge badge-danger" *ngIf="data.status == 'accepted'">Đã được thợ nhận</span>
                                <span class="badge badge-danger" *ngIf="data.status == 'reject'">Đơn hàng bị từ chối</span>
                                <span class="badge badge-danger" *ngIf="data.status == 'on_doing'">Đang tiến hành</span>
                                <span class="badge badge-danger" *ngIf="data.status == 'completed'">Đơn hàng hoàn
                                    thành</span>
                                <span class="badge badge-danger" *ngIf="data.status == 'canceled'">Đơn hàng bị huỷ</span>
                            </td> -->

                            <!-- <td>

                            </td> -->
                            <td (click)="onEdit(data?.note, content)">{{data.created_at | date:'dd/MM/yyyy HH:mm'}}</td>
                            <!-- <td>{{data.updated_at | date:'dd/MM/yyyy HH:mm'}}</td> -->

                            <!-- <td> -->
                                <!-- <a class="dropdown-item" (click)="onDetailBooking(data, detailBooking)">
                                    <i class="mdi mdi-account-edit mr-1"></i>{{'PAGE.REPORT.ViewDetail' | translate}}
                                </a>
                                <a class="dropdown-item" *ngIf="data.images?.length > 0"
                                    (click)="onEdit(data, content)">
                                    <i class="mdi mdi-folder-multiple-image mr-1"></i>{{'PAGE.REPORT.ViewImage' |
                                    translate}}
                                </a> -->

                                <!-- <a class="dropdown-item" href="javascript: void(0);" (click)="onDone(data)">
                                    <i class="mdi mdi-check"></i> Hoàn thành đơn
                                </a>
                                <a class="dropdown-item" href="javascript: void(0);" (click)="onDelete(data)">
                                    <i class="mdi mdi-delete"></i>Huỷ đơn
                                </a> -->

                                <!-- <div ngbDropdown placement="bottom-left">
                                  <i class="mdi mdi-dots-vertical dropdown-toggle" ngbDropdownToggle
                                      data-toggle="dropdown" aria-expanded="true"></i>
                                  <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

                                  </div>
                              </div> -->
                            <!-- </td> -->
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="row justify-content-md-between align-items-md-center mt-2">
                <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                        {{'Hiện' | translate}}
                        {{pageSize*pageIndex - pageSize + 1}}
                        {{'đến' | translate}}
                        {{pageSize*(pageIndex-1) + listData.length}}
                        {{'trên' | translate}}
                        {{totalCount}}
                        {{'bản ghi' | translate}}
                    </div>
                </div>
                <!-- Pagination -->
                <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-end">

                    <div class="text-md-right float-md-right pagination-rounded">
                        <ngb-pagination 
                            [collectionSize]="totalCount" 
                            [(page)]="pageIndex" 
                            [pageSize]="pageSize"
                            [maxSize]="5"
                            [ellipses]="true"
                            (pageChange)="fetchData()">
                        </ngb-pagination>
                    </div>
                    
                    <div class="dataTables_length ml-3" id="tickets-table_length">
                        <label class="d-inline-flex align-items-center mb-0"> {{'Hiện' | translate}}
                            <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                [(ngModel)]="pageSize" (ngModelChange)="pageIndex = 1;fetchData()"
                                class="custom-select custom-select-sm form-control form-control-sm mx-2">
                                <option [ngValue]="10">10</option>
                                <option [ngValue]="25">25</option>
                                <option [ngValue]="50">50</option>
                                <option [ngValue]="100">100</option>
                            </select>
                            {{'bản ghi' | translate}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <!--  -->
            <!-- <button class="btn btn-success mb-2" (click)="openModal(content)">
              <i class="mdi mdi-plus mr-2"></i> Thêm dịch vụ
          </button> -->
        </div>

    </div>
</div>

<!-- <ng-template > -->

<!-- </ng-template> -->


<ng-template #content role="document" let-modal="close">
    <div class="modal-header">
        <h5 class="modal-title">Chi tiết đánh giá</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-3">
        <div class="text-left">
            <!-- <h4>Mã đơn hàng #{{selectedBooking?.id}}</h4> -->
            <div>
                <ng-container>
                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="row">
                                <h6 class="col-md-12 font-weight-bolder">{{'1. Trang phục, tác phong của cán bộ khi tiếp cận Ông/bà có thân thiện, lịch sự không?' | translate}}</h6>
                                <div class="col-md-12">
                                    <strong class=" rounded text-white px-3 {{ dataForm[0] === 'Tốt' ? 'bg-success' : (dataForm[0] === 'Bình thường' ? 'bg-warning' : 'bg-danger')}}">
                                      {{dataForm[0]}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 fs-1">
                            <div class="row">
                                <h6 class="col-md-12 font-weight-bolder">{{'2. Khi hướng dẫn, giải thích hoặc khi kiểm tra thành phần hồ sơ, cán bộ có thông tin đầy đủ, rõ ràng, dễ hiểu không?' | translate}}</h6>
                                <div class="col-md-12">
                                    <strong class=" rounded text-white px-3 {{ dataForm[1] === 'Tốt' ? 'bg-success' : (dataForm[1] === 'Bình thường' ? 'bg-warning' : 'bg-danger')}}">
                                      {{dataForm[1]}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="row">
                                <h6 class="col-md-12 font-weight-bolder">{{'3. Hồ sơ Ông/bà có được giải quyết đúng hạn không?' | translate}}</h6>
                                <div class="col-md-12">
                                    <strong class=" rounded text-white px-3 {{ dataForm[2] === 'Sớm' ? 'bg-success' : (dataForm[2] === 'Đúng hạn' ? 'bg-warning' : 'bg-danger')}}">
                                      {{dataForm[2]}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="row">
                                <h6 class="col-md-12 font-weight-bolder">{{'4. Số lần Ông/bà liên hệ với Bộ phận tiếp nhận và trả kết quả để hoàn thiện hồ sơ (không tính lân nộp hồ sơ đầu tiên và nguyên nhân bổ sung do lối của người nộp hồ sơ)' | translate}}</h6>
                                <div class="col-md-12">
                                    <strong class=" rounded text-white px-3 {{ dataForm[3] === 'Không' ? 'bg-success' : (dataForm[3] === 'Một lần' ? 'bg-warning' : 'bg-danger')}}">
                                      {{dataForm[3]}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="row">
                                <h6 class="col-md-12 font-weight-bolder">{{'5. Ngoài Bộ phận tiếp nhận và trả kết quả, Ông/Bà có gặp cán bộ của cơ quan khác để hoàn thiện hô sơ không?' | translate}}</h6>
                                <div class="col-md-12">
                                    <strong class=" rounded text-white px-3 {{ dataForm[4] === 'Không' ? 'bg-success' : (dataForm[4] === 'Một cơ quan' ? 'bg-warning' : 'bg-danger')}}">
                                      {{dataForm[4]}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="row">
                                <h6 class="col-md-12 font-weight-bolder">{{'6. Cán bộ có thái độ cửa quyền, gây khó khăn, phiên hà đối với Ông/Bà không?' | translate}}</h6>
                                <div class="col-md-12">
                                    <strong class=" rounded text-white px-3 {{ dataForm[5] === 'Không' ? 'bg-success' : (dataForm[5] === 'Bình thường' ? 'bg-warning' : 'bg-danger')}}">
                                      {{dataForm[5]}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="row">
                                <h6 class="col-md-12 font-weight-bolder">{{'7. Ông/bà có ý kiến về nội dung, hình thức công khai TTHC tại Bộ phận Một cửa?' | translate}}</h6>
                                <div class="col-md-12">
                                    <strong class=" rounded text-white px-3 {{ dataForm[6] === 'Đầy đủ, kịp thời, chính xác bằng bản giấy và có hướng dẫn khi công khai bằng hình thức điện tử' ? 'bg-success' : (dataForm[6] === 'Đầy đủ, kịp thời, chính xác bằng bản giấy' ? 'bg-warning' : 'bg-danger')}}">
                                      {{dataForm[6]}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="row">
                                <h6 class="col-md-12 font-weight-bolder">{{'8. Ông/bà có hài lòng với trang thiết bị, điệu kiện phục vụ tại Bộ phận một cửa?' | translate}}</h6>
                                <div class="col-md-12">
                                    <strong class=" rounded text-white px-3 {{ dataForm[7] === 'Hài lòng' ? 'bg-success' : (dataForm[7] === 'Bình thường' ? 'bg-warning' : 'bg-danger')}}">
                                      {{dataForm[7]}}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="text-center mt-5">
                    <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                        (click)="modal('close click')">{{'Đóng' | translate}}</button>
                </div>


            </div>
        </div>
    </div>
</ng-template>


<ng-template #detailBooking role="document" let-modal="close">
    <div class="modal-header">
        <h5 class="modal-title">{{'PAGE.REPORT.IncidentType' | translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-3">
        <div class="text-left">
            <!-- <h4>Mã đơn hàng #{{selectedBooking?.id}}</h4> -->
            <div>
                <ng-container *ngIf="selectedBooking as cus">
                    <div class="row">
                        <div class="col-md-8">
                            <div class=" row">
                                <label class="col-md-3">{{'PAGE.REPORT.trackCode' | translate}}</label>
                                <div class="col-md-9">
                                    <strong>
                                        {{cus?.code}}
                                    </strong>
                                </div>
                            </div>
                            <div class=" row">
                                <label class="col-md-3">{{'PAGE.REPORT.Location' | translate}}</label>
                                <div class="col-md-9">
                                    <strong>
                                        <ng-container *ngIf="cus.location_id; else location_other1">
                                            {{cus?.location_detail?.name}}
                                        </ng-container>
                                        <ng-template #location_other1>
                                            {{cus?.location_other?.formatted_address}}
                                        </ng-template>
                                    </strong>
                                </div>
                            </div>
                            <div class=" row mt-2">
                                <label class="col-md-3">{{'PAGE.REPORT.Detail' | translate}}</label>
                                <div class="col-md-9">
                                    <strong>{{cus.details}}</strong>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <label class="col-md-3">{{'PAGE.REPORT.Type' | translate}}</label>
                                <div class="col-md-9">
                                    <!-- <select class="form-control form-control-sm" [(ngModel)]="formUpdate.type_id">
                                        <option [ngValue]="item.id" *ngFor="let item of listType">{{item.name}}</option>
                                    </select> -->
                                    <!-- <strong *ngIf="!cus.``suggest_type">{{cus.incident_type?.name}}</strong> -->
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="cus.suggest_type">
                                <label class="col-md-3">{{'PAGE.REPORT.SuggestIncident' | translate}}</label>
                                <div class="col-md-9">
                                    <strong class="text-danger">{{cus.suggest_type}}</strong>
                                </div>
                            </div>
                            <div class=" row mt-2">
                                <label class="col-md-3">{{'TABLE.CreatedDate' | translate}}</label>
                                <div class="col-md-9">
                                    <strong>{{cus.created_at | date: 'dd/MM/yyyy HH:mm'}}</strong>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <label class="col-md-3">{{'PAGE.REPORT.Image' | translate}}</label>
                                <div class="col-md-9">
                                    <div class="d-flex" style="gap: 5px; overflow: auto">
                                            <div class="border rounded clickable d-flex position-relative overflow-hidden"
                                                style="padding: 2px; max-width: 200px; max-height: 150px;min-width: 150px; min-height: 150px; border-radius: 5px;"
                                                *ngFor="let item of _albums; let index = index">
                                                <img [src]="item.src" [alt]="item.filename" (click)="open(index)"
                                                    (error)="onImgError($event)" class="img-fluid m-auto mh-100">
                                            </div>
                                            <!-- <span class="font-size-13 mt-2 d-block text-danger"
                                                *ngIf="item.classification">{{item.classification}}</span> -->

                                    </div>
                                    <!-- <button class="btn btn-sm btn-primary" *ngIf="cus.images?.length > 0"
                                        (click)="onEdit(cus, content)">{{'PAGE.REPORT.ViewImage' | translate}}</button> -->
                                </div>
                            </div>
                            <div class=" row mt-2" *ngIf="cus.imageClasstification">
                                <label class="col-md-3">{{'PAGE.REPORT.ImageClasstification' | translate}}</label>
                                <div class="col-md-9">
                                    <strong class="text-danger">{{cus.imageClasstification?.classification}}</strong>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <label class="col-md-3">{{'PAGE.REPORT.Status' | translate}}</label>
                                <div class="col-md-9">
                                    <select class="form-control form-control-sm" [(ngModel)]="formUpdate.status">

                                        <option [ngValue]="1">{{'PAGE.REPORT.New' | translate}}</option>
                                        <option [ngValue]="2">{{'PAGE.REPORT.OnGoing' | translate}}</option>
                                        <option [ngValue]="3">{{'PAGE.REPORT.Pending' | translate}}</option>
                                        <option [ngValue]="4">{{'PAGE.REPORT.Done' | translate}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class=" row mt-2">
                                <label class="col-md-3">{{'PAGE.REPORT.Note' | translate}}</label>
                                <div class="col-md-9">
                                    <textarea class="form-control form-control-sm" [(ngModel)]="formUpdate.note"
                                        rows="3"></textarea>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-4" style="min-height: 280px">
                            <google-map height="100%" width="100%" [center]="center" [zoom]="zoom">
                                <map-marker [position]="center">
                                </map-marker>
                            </google-map>
                        </div>
                    </div>
                </ng-container>
                <div class="text-center mt-5">
                    <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                        (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                    <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                        (click)="updateFormNote()">{{'FORM.Save' | translate}}</button>
                </div>


            </div>
        </div>
    </div>
</ng-template>


<ng-template #contentHistory let-modalHis="modal">
    <div class="modal-header">
        <h5 class="modal-title">Theo dõi lịch sử đơn hàng #{{selectedBookingTracks.id}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modalHis('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-3">
        <div class="table-responsive">
            <table id="basic-datatable" class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
                <thead>
                    <tr>
                        <th>STT</th>
                        <!-- <th>Sự kiện</th> -->
                        <th>Trạng thái</th>
                        <th>Mô tả</th>
                        <th>Thời gian</th>

                    </tr>
                </thead>
                <tbody *ngFor="let data of selectedBookingTracks.order_trackings;let i=index">
                    <tr>
                        <td>{{i+1}}</td>
                        <td>
                            <span *ngIf="(data.status == 'waiting' && !data.employee_id)">Đơn hàng đang chờ</span>
                            <span *ngIf="(data.status == 'waiting' && data.employee_id)">Đơn hàng được chuyển cho nhân
                                viên</span>
                            <span *ngIf="(data.status == 'accepted')">Nhân viên nhận đơn</span>
                            <span *ngIf="(data.status == 'rejected')">Nhân viên từ chối đơn</span>
                            <span *ngIf="(data.status == 'on_doing')">Đơn đang được thực hiện</span>
                            <span *ngIf="(data.status == 'completed')">Đơn hàng hoàn thanh</span>
                            <span *ngIf="(data.status == 'canceled')">Đơn hàng bị huỷ</span>
                            <!-- {{ && 'Đơn hàng đang chờ'}}
                            {{(data.status == 'waiting' && data.employee_id) && 'Đơn hàng được chuyển cho nhân viên'}}
                            {{(data.status == 'accepted') && 'Nhân viên nhận đơn'}}
                            {{(data.status == 'rejected') && 'Nhân viên từ chối đơn'}}
                            {{(data.status == 'on_doing') && 'Đơn đang được thực hiện'}}
                            {{(data.status == 'completed') && 'Đơn hàng hoàn thanh'}}
                            {{(data.status == 'canceled') && 'Đơn hàng bị huỷ'}} -->
                        </td>
                        <td>
                            {{data.notes}}
                            <!-- <span class="badge badge-success" *ngIf="data.body.current_status == 1">Đơn hàng mới</span>
                            <span class="badge badge-danger" *ngIf="data.body.current_status == 2">Đang tìm nhân
                                viên</span>
                            <span class="badge badge-danger" *ngIf="data.body.current_status == 3">Đã được gán</span>
                            <span class="badge badge-danger" *ngIf="data.body.current_status == 4">Đơn hàng chưa được
                                tiếp
                                nhận</span>
                            <span class="badge badge-danger" *ngIf="data.body.current_status == 5">Đơn hàng bị từ
                                chối</span>
                            <span class="badge badge-danger" *ngIf="data.body.current_status == 6">Đang tiến hành</span>
                            <span class="badge badge-danger" *ngIf="data.body.current_status == 7">Đơn hàng hoàn
                                thành</span>
                            <span class="badge badge-danger" *ngIf="data.body.current_status == 8">Đơn hàng bị
                                huỷ</span> -->
                        </td>
                        <!-- <td>
                            <ng-container *ngIf="data.body as d">
                                <span *ngIf="d.previous_employee != d.employee_assigned">Đổi nhân viên</span>
                                <span>
                                    {{d.error}}
                                </span>
                            </ng-container>
                        </td> -->
                        <td>{{data.created_at | date:'dd/MM/yyyy HH:mm:ss'}}</td>

                    </tr>

                </tbody>
            </table>
        </div>
    </div>

</ng-template>
