import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';


export interface ListResponse {
  data: [],
  meta: {
    total?: number
  }
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private httpClient: HttpClient,
        private translate: TranslateService
    ) { }

    getList(table, type = 'admin'){
        return this.httpClient.get(`${table}`).pipe(map(d => d['data'] ? d['data'] : d))
    }

    getList2(table, type = 'admin'){
        return this.httpClient.get(`${table}`).pipe(map((d: ListResponse) =>  d))
    }

    addItem(table, data, type = 'admin'){
        return this.httpClient.post(`${table}`, data)
    }

    editItem(table, id, data, type = 'admin'){
        return this.httpClient.put(`${table}`, data)
    }

    deleteItem(table, id, type = 'admin'){
        return this.httpClient.delete(`${table}/${id}`)
    }

    uploadImage(file){
      return this.httpClient.post(`media`, file)
    }

    showToast(title='Thông báo', text = '', icon , option = {showCancelButton: false, showConfirmButton: false, timer: 4000}){
      Swal.fire({
        title ,
        text,
        icon,
        ...option,
        // showCancelButton: false,
        // showConfirmButton: false,
        // confirmButtonColor: '#5438dc',
        // cancelButtonColor: '#ff3d60'
      });
    }
    successmsg() {
  }
}
