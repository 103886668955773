/// <reference types="@types/googlemaps" />


import { EventService } from './../../core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { latLng, tileLayer } from 'leaflet';

import { Stat, Chat, Transaction } from './dashboard.model';

import { statData, revenueChart, salesAnalytics, sparklineEarning, sparklineMonthly, chatData, transactions } from './data';
import { ChartType } from '../chart/chartist/chartist.model';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {
    mapOptions = {
        mapTypeId: "satellite"
    };
    term: any;
    chatData: Chat[];
    transactions: Transaction[];
    statData: Stat[];

    apiLoaded: Observable<boolean>;

    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
    public chartPlugins = [pluginDataLabels];


    constructor(
        public formBuilder: FormBuilder,
        private apiService: ApiService,
        private eventService: EventService,
        private translate: TranslateService,
    ) {

    }
    locations = [];

    center: google.maps.LatLngLiteral = { lat: 13.786377, lng: 100.608755 };
    zoom = 5;
    markerPositions: google.maps.LatLngLiteral[] = [];
    markerClustererImagePath =
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';


    // totalEmp$ = this.apiService.getList('employees/count');
    // totalPartner$ = this.apiService.getList('partners/count');
    // totalIncome$ = this.apiService.getList('dashboard/income');

    bookingStatus


    // bread crumb items
    breadCrumbItems: Array<{}>;

    revenueChart: ChartType;
    salesAnalytics: ChartType;
    sparklineEarning: ChartType;
    sparklineMonthly: ChartType;

    // Form submit
    chatSubmit: boolean;

    formData: FormGroup;

    listType = []
    // type$ = this.apiService.getList('dashboard/incident-type')
    options = {
        layers: [
            tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
        ],
        zoom: 6,
        center: latLng(46.879966, -121.726909)
    };

    ngAfterViewInit(){
        // google.maps.event.addListener(global_marker_cluster, 'clusterclick', function(cluster)...

    }

    click(e){
        e.stopPropagation();
        // console.log(e)
    }

    fetchMapData(){
        this.locations = []
        this.apiService.getList('incident').subscribe({
            next: res => {
                this.locations = res.map(data => {
                    data['lat'] = 0
                    data['lng'] = 0
                    if(data.location_other && data.location_other.latitude && data.location_other.longitude){
                        data['lat'] = data.location_other.latitude
                        data['lng'] = data.location_other.longitude
                    }else if(data.location_detail && data.location_detail.lat && data.location_detail.lng){
                        data['lat'] = data.location_detail.lat
                        data['lng'] = data.location_detail.lng
                    }
                    return data
                }).filter(i => i.lat && i.lng)
                // console.log(this.locations)
            }
        })
    }

    selectedMarker

    openInfoWindow(marker: MapMarker, data){
        this.infoWindow.open(marker);
        this.selectedMarker = data
        // console.log(this.selectedMarker)
    }

    dataDashboard = <any>{}

    ngOnInit(): void {
        // this.fetchMapData()
        this.eventService.setLoading(true)
        this.apiService.getList('dashboard').subscribe({
            next: res => {
                this.dataDashboard = res
                // this.listType = res.sort((a, b) => a.id - b.id)
                // this.fetchChart()
                this.initChart()
                this.eventService.setLoading(false)
            },
            error: e => {
                this.eventService.setLoading(false)
                this.apiService.showToast(this.translate.instant('Lỗi'), this.translate.instant('Có lỗi xảy ra, vui lòng thử lại sau'), 'error')
            }
        })
        this.breadCrumbItems = [{ label: this.translate.instant('PAGE.HOME') }, { label: this.translate.instant('Thống kê'), active: true }];
        this.formData = this.formBuilder.group({
            message: ['', [Validators.required]],
        });


        // this._fetchData();
    }
    start = 0
    end = 0
    fetchChart() {
        this.eventService.setLoading(true)
        if (this.type == 0) {
            this.start = moment().utc().startOf('day').unix()
        } else if (this.type == 1) {
            this.start = moment().utc().subtract(7, 'd').startOf('day').unix()
        } else if (this.type == 2) {
            this.start = moment().utc().subtract(1, 'months').startOf('day').unix()
        }
        this.end = moment().utc().endOf('day').unix()

        this.apiService.getList(`dashboard/revenue?start_date=${this.start}&end_date=${this.end}&type=4`).subscribe({
            next: res => {
                this.initChart(Object.values(res))
            },
            error: e => {
                this.eventService.setLoading(false)
                this.apiService.showToast(this.translate.instant('Lỗi'), this.translate.instant('Có lỗi xảy ra, vui lòng thử lại sau'), 'error')
            }
        })
    }

    fetchInfo() {

    }



    type = 0 || 1 || 2;
    // type = 'week' || 'month' || 'year';
    // labelWeek = [
    //     this.translate.instant('PAGE.DASHBOARDS.Mon'),
    //     this.translate.instant('PAGE.DASHBOARDS.Tue'),
    //     this.translate.instant('PAGE.DASHBOARDS.Wed'),
    //     this.translate.instant('PAGE.DASHBOARDS.Thu'),
    //     this.translate.instant('PAGE.DASHBOARDS.Fri'),
    //     this.translate.instant('PAGE.DASHBOARDS.Sat'),
    //     this.translate.instant('PAGE.DASHBOARDS.Sun')]

    // labelMonth = [
    //     this.translate.instant('PAGE.DASHBOARDS.Jan'),
    //     this.translate.instant('PAGE.DASHBOARDS.Feb'),
    //     this.translate.instant('PAGE.DASHBOARDS.Mar'),
    //     this.translate.instant('PAGE.DASHBOARDS.Apr'),
    //     this.translate.instant('PAGE.DASHBOARDS.May'),
    //     this.translate.instant('PAGE.DASHBOARDS.Jun'),
    //     this.translate.instant('PAGE.DASHBOARDS.Jul'),
    //     this.translate.instant('PAGE.DASHBOARDS.Aug'),
    //     this.translate.instant('PAGE.DASHBOARDS.Sep'),
    //     this.translate.instant('PAGE.DASHBOARDS.Oct'),
    //     this.translate.instant('PAGE.DASHBOARDS.Nov'),
    //     this.translate.instant('PAGE.DASHBOARDS.Dev')
    // ]

    chart = <any>{
        options: {
            maintainAspectRatio: false,
            plugins: {

                datalabels: {
                    formatter: (value, ctx) => {
                      let sum = 0;
                      let dataArr: any[] = ctx.chart.data.datasets[0].data;
                      dataArr.map((data: number) => {
                        sum += data;
                      });
                      let percentage = (value * 100 / sum).toFixed(2) + "%";
                      return percentage;
                    },
                    color: '#fff',
                  }
              }
            // scales: {
            //     xAxes: [
            //         {
            //             ticks: {
            //                 beginAtZero: true,
            //             },
            //             gridLines: {
            //                 color: 'rgba(166, 176, 207, 0.1)'
            //             },
            //         }
            //     ],
            //     yAxes: [
            //         {
            //             gridLines: {
            //                 color: 'rgba(166, 176, 207, 0.1)'
            //             },
            //             ticks: {
            //                 beginAtZero: true,
            //                 callback: function (value) { if (value % 1 === 0) { return value; } }
            //             },
            //         }
            //     ]
            // },
        }
    }
    loadedChart = false
    initChart(data_ = []) {
        this.loadedChart = false
        this.chart['labels'] = ['Rất tốt', 'Tốt', 'Trung bình', 'Không tốt']
        this.chart['datasets'] = [
            {
                data: [this.dataDashboard.very_good, this.dataDashboard.good, this.dataDashboard.normal, this.dataDashboard.bad],
                backgroundColor: ['#1cbb8c','#5664d2','#fcb92c','#ff3d60'],
                // borderColor: 'rgba(52, 195, 143, 0.8)',
                // borderWidth: 1,
                // hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
                // hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
            }
        ]

        this.loadedChart = true
        this.eventService.setLoading(false)
    }
    // initChart(data?) {
    //     this.loadedChart = false
    //     if (this.type == 0) {
    //         this.chart['labels'] = this.labelWeek

    //     } else if (this.type == 1) {
    //         this.chart['labels'] = this.labelMonth
    //     } else {
    //         this.chart['labels'] = Object.keys(data)
    //         // this.chart['labels'] =
    //     }
    //     // console.log(data)
    //     let dataSet = this.listType.map(i => {
    //         let d = <any>{}
    //         d['label'] = i.name
    //         d['id']= i.type_id
    //         d['data'] = []
    //         return d
    //     })
    //     Object.keys(data).forEach(keys => {
    //         const element = data[keys]
    //         // console.log(element)
    //         if(element){
    //             dataSet.forEach(i => {
    //                 let find = element.find(e => e.type_id == i.id)
    //                 i.data.push(find?.count || 0)
    //             })
    //         }else{
    //             dataSet.forEach(i => i['data'].push(0))
    //         }
    //     })
    //     this.chart['datasets'] = dataSet
    //     this.loadedChart = true
    //     this.eventService.setLoading(false)
    // }


}
