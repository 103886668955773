<app-pagetitle title="{{'Quản lý đơn vị' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<div class="card">
    <div class="card-body">
        <div class="mb-2">
            <h5>{{'Bộ lọc' | translate}}</h5>
            <div class="row">
                <div class="flex-fill row ml-1">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="validationCustom01">{{'Tên đơn vị' | translate}}</label>
                            <ng-select [(ngModel)]="filter.location_id" [clearable]="false">
                                <ng-option [value]="-1">{{'Tất cả' | translate}}</ng-option>
                                <ng-option [value]="item.id" *ngFor="let item of listLocation">
                                    {{item.org_name}}</ng-option>
                                <!-- <ng-option [value]="0"> {{'PAGE.REPORT.other' | translate}}</ng-option> -->
                            </ng-select>
                        </div>
                    </div>
                 
                <div style="min-width: 200px; padding-left: 15px">
                    <div class="form-group">
                        <label for="validationCustom01" class="d-block">&nbsp;</label>
                    <button class="btn btn-primary" (click)="submitFilter()">{{'Tìm kiếm' |
                            translate}}</button>
                        <button class="btn btn-dark ml-2" *ngIf="searching" (click)="clearFilter()">{{'Làm mới' |
                            translate}}</button>
                    </div>
                </div>
            </div>
            </div>

            <!-- End search -->
        </div>
        <div class="d-flex justify-content-end">
            <!--  -->
            <button class="btn btn-success mb-2" (click)="openModal(content)">
                <i class="mdi mdi-plus mr-2"></i> {{'Thêm mới' | translate}}
            </button>
        </div>
        <div class="row mb-md-2">

        </div>
        <div class="table-responsive">
            <table id="basic-datatable" class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Mã đơn vị</th>
                        <th>Tên đơn vị</th>
                        <th>Thời gian tạo</th>
                        <th>Thời gian cập nhật</th>
                        <!-- <th>{{'TABLE.UpdatedDate' | translate}}</th> -->
                        <th width="100">{{'' | translate}}</th>
                    </tr>
                </thead>
                <tbody *ngFor="let data of listData;let i=index">
                    <tr>
                        <td>{{pageSize*(pageIndex-1) +i+1}}</td>
                        <td>{{data.code}}</td>
                        <td>{{data.org_name}}</td>
                        <!-- <td>{{data.description}}</td>
                        <td>
                            <ng-container *ngFor="let pr of data.prices">
                              <p>
                                {{pr.condition == 1 ? 'T2-T6' : 'T7,CN'}}:&nbsp;
                                {{pr.price | number: '1.0-2'}} đ</p>
                            </ng-container>

                        </td> -->
                        <!-- <td>
                            <span class="badge badge-success" *ngIf="data.status == 1">Đang hoạt động</span>
                            <span class="badge badge-danger" *ngIf="data.status != 1">Ngừng hoạt động</span>
                        </td> -->
                        <td>{{data.created_at | date:'dd/MM/yyyy HH:mm':'GMT'}}</td>
                        <td>{{data.updated_at | date:'dd/MM/yyyy HH:mm':'GMT'}}</td>

                        <td class="py-2">
                            <div class="d-flex" style="gap: 5px">

                                <a class="font-size-18" href="javascript: void(0);" ngbTooltip="{{'Cập nhật' | translate}}" placement="bottom-left" (click)="onEdit(data, content)">
                                    <i class="mdi mdi-account-edit"></i>
                                </a>
                                <a class="font-size-18 text-danger" ngbTooltip="{{'Xoá' | translate}}" placement="bottom-left" (click)="onDelete(data.id)">
                                    <i class="mdi mdi-delete"></i>
                                </a>
                                <a class="font-size-18 text-body" ngbTooltip="{{'Reset mật khẩu' | translate}}" placement="left" (click)="onResetEmployeePassword(data.id)">
                                    <i class="mdi mdi-refresh"></i>
                                </a>
                            </div>

                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
                <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                    {{'Hiện' | translate}}
                    {{pageSize*pageIndex - pageSize + 1}}
                    {{'đến' | translate}}
                    {{pageSize*pageIndex}}
                    {{'trên tổng' | translate}}
                    {{totalCount}}
                    {{'bản ghi' | translate}}
                </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-end">

                <div class="text-md-right float-md-right pagination-rounded">
                    <ngb-pagination 
                    [collectionSize]="totalCount" 
                    [(page)]="pageIndex" 
                    [pageSize]="pageSize"
                    [maxSize]="5"
                    [ellipses]="true"
                    (pageChange)="fetchData()">
                </ngb-pagination>
                </div>
                <div class="dataTables_length ml-3" id="tickets-table_length">
                    <label class="d-inline-flex align-items-center mb-0"> {{'Hiện' | translate}}
                        <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                            [(ngModel)]="pageSize" (ngModelChange)="pageIndex = 1;fetchData()"
                            class="custom-select custom-select-sm form-control form-control-sm mx-2">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                        {{'bản ghi' | translate}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #content role="document" let-modal="close">
    <div class="modal-header">
        <h5 class="modal-title">{{(formData.value.id ? "Cập nhật" : "Thêm mới") | translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-3">
        <div class="text-left">
            <form (ngSubmit)="saveData()" [formGroup]="formData">
                <div class="form-group row">
                    <label class="col-md-2 col-form-label required_label">{{'Mã đơn vị' | translate}}</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control "  formControlName="code"
                            [ngClass]="{'is-invalid': submitted && form.code.errors}" />
                        <!-- <div *ngIf="submitted && form.username.errors" class="invalid-feedback">
                            <span *ngIf="form.username.errors.required">{{'' | translate}}</span>
                        </div> -->
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label required_label">{{'Tên đơn vị' | translate}}</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control" formControlName="org_name"
                            [ngClass]="{'is-invalid': submitted && form.org_name.errors}" />
                        <!-- <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
                            <span *ngIf="form.name.errors.email">{{'Email không hợp lệ' | translate}}</span>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <label class="col-md-2 col-form-label">Đơn vị tiền tệ</label>
                    <div class="col-md-10">
                      <select class="form-control" formControlName="currency">
                          <option value="USD">$</option>
                          <option value="VND">VNĐ</option>
                          <option value="GBP">£</option>
                          <option value="CNY">¥</option>
                      </select>
                        </div>


                </div> -->

                <!-- <div class="form-group row">
                    <label class="col-md-2 col-form-label">Ảnh</label>
                    <div class="col-md-10">

                        <div class="custom-file">
                            <input type="file" accept="image/png, image/jpeg" class="custom-file-input" id="customFile" (change)="handleFileInput($event)">
                            <label class="custom-file-label" for="customFile">Chọn ảnh</label>
                        </div>
                        <div class="img-placeholder mt-2" *ngIf="uploadedImage" >
                            <img [src]="uploadedImage" alt="avatar" class="img-fluid">
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-2 col-form-label">Trạng thái</label>
                    <div class="col-md-10">
                      <div class="custom-control custom-switch mb-2" dir="ltr">
                          <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="status">
                          <label class="custom-control-label" for="customSwitch1">Hoạt động</label>
                      </div>

                    </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Mô tả</label>
                    <div class="col-md-10">

                      <textarea formControlName="description" class="form-control" rows="4"></textarea>
                    </div>
                </div> -->


                <div class="text-right">
                    <button type="button" class="btn btn-secondary  mr-3 w-25"
                        (click)="modal('close click')">{{'Đóng' | translate}}</button>
                    <button type="submit" class="btn btn-primary  w-25">{{'Lưu' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>


