import { EventService } from './../../core/services/event.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { env } from 'process';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-unitmanager',
  templateUrl: './unitmanager.component.html',
  styleUrls: ['./unitmanager.component.scss']
})
export class UnitmanagerComponent implements OnInit{

  constructor(
      private apiService: ApiService,
      private modalService: NgbModal,
      public formBuilder: FormBuilder,
      private eventService: EventService,
      private translateService: TranslateService
      // private modalService: NzModalService,
      // private notification: NzNotificationService
  ) { }
  searchTerm
  listData = []
  pageSize = 10
  pageIndex = 1
  totalCount = 2
  tableName = 'locations'

  submitted: boolean
  formData: FormGroup

  breadCrumbItems
  filter = {
    user_id: -1,
    level: -1,
    type_id: -1,
    location_id: -1,
    // status: -1,
    start_date: -1,
    end_date: -1
}
params = ''
searching = false
  imgHost = environment.imageHost
  listLocation = []
  get form() {
      return this.formData.controls
  }

  ngOnInit() {
      this.breadCrumbItems = [{ label: 'PAGE.HOME' }, { label: 'Quản lý đơn vị', active: true }];
      this.fetchData()
     this.getDataOption()
  }

  onChangePage(e) {
      this.pageIndex = e
      this.fetchData()
  }
  getDataOption() {
    // let params =''
    // if(this.keyword){
    //     params ='&name='+this.keyword
    // }
    // if(this.selectedStation&& this.selectedStation.length >0){
    //     this.selectedStation.forEach(d => {
    //         params += `&station_id=${d}`
    //     })
    // }
  //   params = '&order=updated_at&direction=desc'
    this.listData = []
    this.apiService.getList2(this.tableName).subscribe({
        next: (res) => {
            const data = res['data']
            this.listLocation = [...data]
        }
    })
}


  fetchData() {
      // let params =''
      // if(this.keyword){
      //     params ='&name='+this.keyword
      // }
      // if(this.selectedStation&& this.selectedStation.length >0){
      //     this.selectedStation.forEach(d => {
      //         params += `&station_id=${d}`
      //     })
      // }
    //   params = '&order=updated_at&direction=desc'
      this.eventService.setLoading(true)
      this.listData = []
      this.apiService.getList2(this.tableName + `?page=${this.pageIndex}&limit=${this.pageSize}${this.params}`).subscribe({
          next: (res) => {
              const data = res['data']
              // const meta = res['total']
              // this.totalCount= res.total
              this.listData = [...data]
              this.eventService.setLoading(false)
              this.totalCount = res['total']
          }
      })
  }

    submitFilter() {
        this.params = ''
        Object.keys(this.filter).forEach(key => {
            // console.log(key+ '-'+ this.filter[key])
            if (this.filter[key] >=0) {
                this.params += `&${key}=${this.filter[key]}`
            }
        })
        if(this.params){
            this.pageIndex = 1
            this.searching = true
            this.fetchData()
        }
    }
    clearFilter(){
        // this.selected = ''
        this.params = ''
        this.filter.user_id = -1
        this.filter.location_id = -1
        this.filter.level = -1
        this.filter.type_id = -1
        // this.filter.status = -1
        // this.filter.location_id = -1
        this.filter.start_date = 0
        this.filter.end_date = 0
        this.pageIndex = 1
        this.searching = false
        this.fetchData()
    }
  addLang() {
      const language = this.form.language as FormArray
      let lang = this.makePrice()
      language.push(lang)
  }

  removeLang(i) {
      const language = this.form.language as FormArray
      language.removeAt(i)
  }

  openModal(content: any) {
      // this.apiService.successmsg()
      this.submitted = false
      this.formData = this.makeForm({ language: [{}] })
      let a = this.modalService.open(content, { centered: true, scrollable: true, size: 'lg' });
  }

  onEdit(data, content) {
      // console.log(data)
      this.formData = this.makeForm(data)
      // this.uploadedImage = data.media.url
      this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }

  selectedIncident = null
  selectedKeyword = null

  makeFormKeyword(d) {
      let data = d ? d : <any>{}
      return this.formBuilder.group({
          id: [data.id],
          name: [data.name],
          incident_type_id: [data.incident_type_id]
      })
  }

  fetchKeyword() {
      this.dataKeyword = []
      this.eventService.setLoading(true)
      this.apiService.getList(`keyword?type_id=${this.selectedIncident.id}`).subscribe({
          next: res => {
              // console.log(res)
              this.dataKeyword = [...res]
              this.eventService.setLoading(false)
          }
      })
  }

  dataKeyword = []

  formEditKeyword: FormGroup
  formNewKeyword: FormGroup
  editKeyword(data, index) {
      this.formEditKeyword = this.makeFormKeyword(data)
      this.selectedKeyword = data
  }

  onSubmitFormKeyword(type) {
      if (type == 1) {
          if (this.formEditKeyword.valid) {
              const value = this.formEditKeyword.value
              this.eventService.setLoading(true)
              this.apiService.editItem('keyword', value.id, value).subscribe({
                  next: res => {
                      this.selectedKeyword = null
                      this.fetchKeyword()
                      this.eventService.setLoading(false)

                  }
              })
          }
      } else {
          if (this.formNewKeyword.valid) {
              const value = this.formNewKeyword.value
              this.eventService.setLoading(true)
              this.apiService.addItem('keyword', value).subscribe({
                  next: res => {
                      this.eventService.setLoading(false)
                      this.formNewKeyword.reset({ incident_type_id: this.selectedIncident.id })
                      this.fetchKeyword()
                  }
              })
          }
      }
  }

  onOpenKeywordModal(content, incident) {
      this.selectedIncident = incident
      this.fetchKeyword()
      this.formNewKeyword = this.makeFormKeyword({ incident_type_id: this.selectedIncident.id })
      this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }

  defaultPrices = [
      { condition: 1, price: 0, unit: '' },
      { condition: 2, price: 0, unit: '' }
  ]

  onDeleteKeyword(id) {
      if (id) {
          this.eventService.setLoading(true)
          this.apiService.deleteItem('keyword', id).subscribe(res => {

              Swal.fire(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageDelete'), 'success')
                  .then(
                      res => {
                          this.fetchKeyword();

                      }
                  )
              this.eventService.setLoading(false)

          }

          )
      }
  }

  onDelete(data) {
      Swal.fire({
          title: this.translateService.instant('Cảnh báo Xoá đơn vị'),
          text: this.translateService.instant('Hành động này không thể hoàn tác.'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#ff3d60',
          confirmButtonText: this.translateService.instant('Xác nhận')
      }).then(result => {
          if (result.value) {
              this.apiService.deleteItem(this.tableName, data).subscribe(res =>
                  Swal.fire(this.translateService.instant('Thành công'), this.translateService.instant('Xoá đơn vị thành công'), 'success')
                      .then(
                          res => {
                              this.fetchData();
                          }
                      )
              )
          }
      });
  }


  onResetEmployeePassword(data) {
      Swal.fire({
          title: this.translateService.instant('Cảnh báo Reset mật khẩu'),
          text: this.translateService.instant('Hành động này không thể hoàn tác. Mật khẩu mặc định (123456)'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#ff3d60',
          confirmButtonText: this.translateService.instant('Xác nhận')
      }).then(result => {
          if (result.value) {
              this.apiService.addItem('users/reset-password', {id: data}).subscribe(res =>
                  Swal.fire(this.translateService.instant('Thành công'), this.translateService.instant('Cài lại mật khẩu thành công. Mật khẩu mới (123456)'), 'success')
                      .then(
                          res => {
                              this.fetchData();
                          }
                      )
              )
          }
      });
  }



  makeForm(d?) {
      let data = d ? d : <any>{}
      if(data.media && data.media.id){
          this.uploadedImage = data.media.url
      }
      return this.formBuilder.group({
          id: [data.id],
        //   username: [{value: data.username, disabled: data.id ? true : false}, [Validators.required]],
          org_name: [data.org_name, [Validators.required]],
          code: [data.code, [Validators.required]],
        //   full_name: [data.full_name],
        //   short_name: [data.short_name],
        //   role: [data.role || 'employee'],
        //   phone: [data.phone],
        //   field: [data.field],
        //   counter: [data.counter],
        //   status: [data.status || true],
        //   department_id: [data.department_id || 1],
        //   password: [data.password || '123456', data.id ? [Validators.required]: []],
          // language: this.makePrices(data.language || [{}])
          // description: [data.description],
          // price: [data.price || 0, [Validators.required]],
          // prices: this.makePrices(data.prices || this.defaultPrices),
          // status: [data.status || false],
          // image_url: [data.image_url, [Validators.required]],
        //   media_id: [data.media_id],
          // parent_id: [data.parent_id || 0],
          // currency: [data.currency || 'VND'],
      })
  }

  makePrices(data = []) {
      let arr = new FormArray([]);
      data.forEach(i => {

          arr.push(this.makePrice(i))
      })
      return arr;
  }

  makePrice(d?) {
      let data = d ? d : <any>{}
      return this.formBuilder.group({
          key: [data.key, [Validators.required]],
          // price: [data.price, [Validators.required, Validators.min(0)]],
          value: [data.value, [Validators.required]],
          // id: [data.id]
      })
  }

  uploadedImage

  handleFileInput(fileInput) {
      // console.log(fileInput)
      let file = fileInput.target.files[0]
      const formData: FormData = new FormData();
      formData.append('file[0]', file);
      this.uploadedImage = null
      this.apiService.uploadImage(formData).pipe(map(v => v['data'])).subscribe(v => {
          if (v && v.length > 0) {
              // console.log(v)
              let url = v[0]['url'];
              this.uploadedImage = url
              // console.log(this.uploadedImage)
              this.form['media_id'].patchValue(v[0]['id'])
          }
      })
  }

  saveData() {
      this.submitted = true
      if (this.formData.valid) {
          const value = this.formData.value
          value['status'] = value['status'] == true ? 1 : 0
          // value['prices'][0]['condition'] = 1
          // value['prices'][1]['condition'] = 2
          // value['price']= value['prices']
          this.eventService.setLoading(true)
          if (value.id) {
              this.apiService.editItem(this.tableName, value.id, value).subscribe({
                  next: res => {
                      this.submitted = false
                      this.formData.reset()
                      this.fetchData()
                      this.modalService.dismissAll()
                      this.apiService.showToast('Thành công', 'Cập nhật thông tin', 'success')
                      this.eventService.setLoading(false)
                  },
                  error: e => {
                      this.apiService.showToast('Lỗi', 'Có lỗi xảy ra', 'error')
                      this.eventService.setLoading(false)
                  }
              })
          } else {
              this.apiService.addItem(this.tableName, value).subscribe({
                  next: res => {
                      this.fetchData()
                      this.apiService.showToast('Thành công', 'Thêm mới thông tin thành công', 'success')
                      this.eventService.setLoading(false)
                      this.modalService.dismissAll()
                  },
                  error: e => {
                      this.apiService.showToast('Lỗi', 'Có lỗi xảy ra', 'error')
                      this.eventService.setLoading(false)
                  }
              })
          }

      } else {
          // console.log('invalid', this.formData)
          Object.values(this.formData.controls).forEach(control => {
              if (control.status == 'INVALID') {
                  control.markAsDirty();
                  control.updateValueAndValidity({ onlySelf: true });
              }
          });
      }
  }

}